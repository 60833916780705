<template>
  <div>
    <breadcrumb name="Service Appointment" title="Settings" subtitle="Emails"/>
    <div class="body">
      <h6>Emails</h6>
      <vs-row v-for="(item, index) in data" :key="index" style="margin: 20px">
        <vs-col vs-lg="11" vs-sm="12" class="settings">
          <vs-input v-model="item.booking_service_emails" class="input" size="medium" :label="item.brand_name"/>
        </vs-col>
      </vs-row>

      <div class="optionBtns">
        <vs-button
          class="mr-5 save"
          icon-pack="feather"
          icon="icon-save"
          @click="confirmSaveData"
        >Save
        </vs-button>
        <vs-button
          class="ml-5 cancel"
          type="border"
          color="primary"
          @click="getBrands"
        >Cancel
        </vs-button>
      </div>

      <!--      <vx-card title="Hot Lines" class="mb-base" style="position: relative">-->
      <!--        <vs-row style="margin: 20px">-->
      <!--          <vs-col vs-lg="11" vs-sm="12" class="settings">-->
      <!--            <vs-input v-model="hotlines.rsa_call_number" style="width: 100%" label="First RSA Call Number"/>-->
      <!--          </vs-col>-->
      <!--        </vs-row>-->

      <!--        <vs-row style="margin: 20px">-->
      <!--          <vs-col vs-lg="11" vs-sm="12" class="settings">-->
      <!--            <vs-input v-model="hotlines['2d_rsa_call_number']" style="width: 100%" label="Second RSA Call Number"/>-->
      <!--          </vs-col>-->
      <!--        </vs-row>-->

      <!--        <div class="optionBtns">-->
      <!--          <vs-button-->
      <!--            class="mr-5 save"-->
      <!--            icon-pack="feather"-->
      <!--            icon="icon-save"-->
      <!--            @click="confirmSaveData2"-->
      <!--          >Save-->
      <!--          </vs-button>-->
      <!--          <vs-button-->
      <!--            class="ml-5 cancel"-->
      <!--            type="border"-->
      <!--            color="primary"-->
      <!--            @click="getHotlines"-->
      <!--          >Cancel-->
      <!--          </vs-button>-->
      <!--        </div>-->
      <!--      </vx-card>-->

    </div>
  </div>
</template>

<script>
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    components: { Breadcrumb },
    data() {
      return {
        setting: [],
        brands: [],
        data: [],
        hotlines: {},
        line1: "",
        line2: ""
      };
    },
    methods: {
      getHotlines() {
        this.$vs.loading();
        this.$httpAdmin.get("config/rsa")
          .then(r => {
            this.$vs.loading.close();
            this.hotlines = r.data.data;
            console.log({ hotlines: this.hotlines });
            //console.log(r);
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
          });
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      async saveEmails() {
        let data = [];

        for (let i in this.data) {
          data.push({});
          let comma = this.data[i].booking_service_emails.lastIndexOf(',');
          if(comma === -1) {
            data[i].booking_service_emails = [this.data[i].booking_service_emails];
            data[i].brand_id = this.data[i].brand_id;
          }else {
            data[i].booking_service_emails = this.data[i].booking_service_emails.split(",");
            data[i].brand_id = this.data[i].brand_id;
          }
          try {
            await this.$httpAdmin.post("brand-configs/update", data[i]);
          } catch (e) {
            console.log({ e });
          }
        }

        console.log(data);
      },
      async saveData() {
        this.$vs.loading();
        try {
          await this.saveEmails();
        } catch (e) {

          this.$vs.loading.close();
          console.log(e);
          this.$vs.notify({
            title: "Error",
            text: "Error is occurred!\nCouldn't Save Data!",
            color: "danger"
          });
        }
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Saved",
          text: "Data is Saved Successfully",
          color: "primary"
        });
      },
      getBrands() {
        this.$vs.loading();
        this.$httpCars
          .get(`/brands`)
          .then(res => {
            this.$vs.loading.close();
            this.brands = res.data.data.brands;

            //console.log({ brands: this.brands });

            this.$vs.loading();
            this.$httpAdmin
              .get(`/brand-configs`)
              .then(res => {
                this.$vs.loading.close();
                this.settings = res.data.data;

                //console.log({ settings: this.settings });

                let b = this.brands;
                this.data = [];

                for (let x in this.settings) {
                  for (let i in this.brands) {
                    if (this.brands[i].id === this.settings[x].brand_id) {
                      this.data.push(this.settings[x]);
                      b.splice(i, 1);
                    }
                  }
                }

                for(const item of this.data){
                  item.booking_service_emails = item.booking_service_emails.join(",");
                }

                for (let i in b) {
                  this.data.push({
                    brand_id: b[i].id,
                    brand_name: b[i].name_en,
                    rsa_emails: [],
                    booking_service_emails: []
                  });
                }

                for (let i in this.data) {
                  this.data[i].rsa_emails = this.data[i].rsa_emails.toString();
                }

                //console.log({ data: this.data });
              })
              .catch(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error",
                  text: "Couldn't fetch data from the server",
                  color: "danger"
                });
              });


          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: "Couldn't fetch data from the server",
              color: "danger"
            });
          });
      }
    },
    mounted() {
      this.getBrands();
      this.getHotlines();
    }
  };
</script>

<style scoped lang="scss">
  .body {
    border-radius: 10px;
    padding: 20px;
  }

  .optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .input {
    width: 100%;
    margin: 0 !important;
  }
</style>
